/**
 * バリデーション
 *
 * @return {*}  {*}
 */
const useFormRules = (): any => {
  /**
   * 必須入力チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isRequired = (value: any): string | boolean => {
    return !!value || '必須入力項目です';
  };

  /**
   * 必須入力チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumberRequired = (value: any): string | boolean => {
    if (!String(value).match(/^[0-9]+$/)) {
      return '必須入力項目です';
    }
    return true;
  };

  /**
   * メールフォーマットチェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   *
   * Extracted from PHP core.
   * @link https://github.com/php/php-src/blob/master/ext/filter/logical_filters.c
   */
  const isEmail = (value: any): string | boolean => {
    if (!value) {
      return true;
    }

    if (
      // !String(value).match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      // php_filter_validate_emailの正規表現と合わせる（RFC822）
      /* eslint-disable no-control-regex */
      !String(value).match(
        /^(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){255,})(?!(?:(?:\x22?\x5C[\x00-\x7E]\x22?)|(?:\x22?[^\x5C\x22]\x22?)){65,}@)(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22))(?:\.(?:(?:[\x21\x23-\x27\x2A\x2B\x2D\x2F-\x39\x3D\x3F\x5E-\x7E]+)|(?:\x22(?:[\x01-\x08\x0B\x0C\x0E-\x1F\x21\x23-\x5B\x5D-\x7F]|(?:\x5C[\x00-\x7F]))*\x22)))*@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)?[a-z0-9]+(?:-+[a-z0-9]+)*\.){1,126}){1,}(?:(?:[a-z][a-z0-9]*)|(?:(?:xn--)[a-z0-9]+))(?:-+[a-z0-9]+)*)|(?:\[(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){7})|(?:(?!(?:.*[a-f0-9][:\]]){7,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?)))|(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){5}:)|(?:(?!(?:.*[a-f0-9]:){5,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3})?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})|(?:[1-9]?[0-9]))){3}))\]))$/i
      )
    ) {
      return 'メールアドレスが正しくありません';
    }
    return true;
  };

  /**
   * 半角数字(小数点を含む)チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isDecimal = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^[-]?([1-9]\d*|0)(\.\d+)?$/)) {
      return '半角数字で入力してください';
    }
    return true;
  };

  /**
   * 半角数字・半角ハイフンチェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumberHyphen = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^[0-9-]+$/)) {
      return '半角数字と半角ハイフンで入力してください';
    }
    return true;
  };

  /**
   * カナチェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isKana = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!value.match(/^[ァ-ヶー]*$/)) {
      return 'カナを入力してください';
    }
    return true;
  };

  /**
   * 半角数字チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumber = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^[0-9]+$/)) {
      return '半角数字で入力してください';
    }
    return true;
  };

  /**
   * 半角英数字チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isAlphanumeric = (value: any): string | boolean => {
    if (!value) {
      return true;
    }
    if (!String(value).match(/^[0-9a-zA-Z]+$/)) {
      return '半角英数字で入力してください';
    }
    return true;
  };

  /**
   * 1-31までの半角数字チェック
   *
   * @param {*} value
   * @param required
   * @return {*}  {(string | boolean)}
   */
  const isDateNumber = (value: any, required = true): string | boolean => {
    if (!required && (value === '' || value === null)) {
      return true;
    }

    if (!String(value).match(/^[0-9]+$/)) {
      return '半角数字で入力してください';
    }

    if (value <= 0 || value > 31) {
      return '1から31までの整数を半角で入力してください';
    }
    return true;
  };

  /**
   * 金額が（1以上）チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumberPlusRequired = (value: any): string | boolean => {
    if (value <= 0) {
      return '1以上の整数を半角で入力してください';
    }
    if (!String(value).match(/^[0-9]+$/)) {
      return '1以上の整数を半角で入力してください';
    }
    return true;
  };

  /**
   * 金額(±)チェック
   *
   * @param {*} value
   * @return {*}  {(string | boolean)}
   */
  const isNumberPlusOrMinusRequired = (value: any): string | boolean => {
    if (!String(value).match(/^[0-9-]+$/)) {
      return '整数を半角で入力してください';
    }
    return true;
  };

  /**
   * 24時間表記制時刻チェック
   *
   * @param {*} value
   * @return {*} {(string|boolean)}
   */
  const isTime24HourNotation = (value: any): string | boolean => {
    if (!value) {
      return true;
    }

    if (!String(value).match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/)) {
      return '半角数字とコロン・24時間表記で入力してください　例：18:00';
    }

    return true;
  };

  /**
   * 24時間表記制開始時刻チェック
   *
   * @param {*} value
   * @param {*} value2
   * @return {*} {(string|boolean)}
   */
  const isTime24HourNotationStart = (value: any, value2: any): string | boolean => {
    if (!value && !value2) {
      return true;
    }

    if (!String(value).match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/)) {
      return '半角数字とコロン・24時間表記で入力してください　例：18:00';
    }

    if (!value2) {
      return '終了時間も入力してください';
    }

    const times = String(value).split(':');
    const times2 = String(value2).split(':');

    if (
      Number(times[0]) > Number(times2[0]) ||
      (Number(times[0]) === Number(times2[0]) && Number(times[1]) > Number(times2[1]))
    ) {
      return '終了時間が開始時間より遅くなるように入力してください';
    }

    return true;
  };

  /**
   * 24時間表記制終了時刻チェック
   *
   * @param {*} value
   * @param {*} value2
   * @return {*} {(string|boolean)}
   */
  const isTime24HourNotationEnd = (value: any, value2: any): string | boolean => {
    if (!value && !value2) {
      return true;
    }

    if (!String(value2).match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/)) {
      return '半角数字とコロン・24時間表記で入力してください　例：18:00';
    }

    if (!value) {
      return '開始時間も入力してください';
    }

    const times = String(value).split(':');
    const times2 = String(value2).split(':');

    if (
      Number(times[0]) > Number(times2[0]) ||
      (Number(times[0]) === Number(times2[0]) && Number(times[1]) > Number(times2[1]))
    ) {
      return '終了時間が開始時間より遅くなるように入力してください';
    }

    return true;
  };

  /**
   * 最大文字数チェック
   *
   * @param {*} value
   * @param {*} value2
   * @return {*} {(string|boolean)}
   */
  const isMaxLen = (value: any, max: number): string | boolean => {
    if (!value) {
      return true;
    }

    if (String(value).length > max) {
      return `${max}文字以内で入力してください`;
    }

    return true;
  };

  /**
   * 日付チェック
   *
   * @param {*} value
   * @return {*} {(string|boolean)}
   */
  const isDate = (value: any): string | boolean => {
    if (!value) {
      return true;
    }

    if (!value.match(/^\d{4}\-\d{1,2}\-\d{1,2}$/)) {
      return '半角数字とハイフンで入力してください　例：2023-01-01';
    }
    const y = value.split('-')[0];
    const m = value.split('-')[1] - 1;
    const d = value.split('-')[2];
    const date = new Date(y, m, d);
    if (date.getFullYear() != y || date.getMonth() != m || date.getDate() != d) {
      return '半角数字とハイフンで入力してください　例：2023-01-01';
    }
    return true;
  };

  /**
   * 最大数字チェック
   *
   * @param {*} value
   * @param min
   * @param max
   * @return {*} {(string|boolean)}
   */
  const isNumberBetween = (value: any, min: number, max: number, required = true): string | boolean => {
    if (!required && (value === '' || value === null)) {
      return true;
    }

    if (value < min || max < value) {
      return `${min}〜${max}の間で入力してください`;
    }

    return true;
  };

  return {
    isRequired,
    isNumberRequired,
    isEmail,
    isDecimal,
    isNumberHyphen,
    isKana,
    isNumber,
    isAlphanumeric,
    isNumberPlusRequired,
    isNumberPlusOrMinusRequired,
    isTime24HourNotation,
    isTime24HourNotationStart,
    isTime24HourNotationEnd,
    isMaxLen,
    isDateNumber,
    isDate,
    isNumberBetween
  };
};

export { useFormRules };
